import "./SubscriptionModelComponent.css";

import React, { useState } from "react";
import "./SubscriptionModelComponent.css";
import { SubscriptionModel } from "../../api/graphql/schemas";
import {
  getSubscriptionModelPriceDisplay,
  getTimeSpanMembershipDisplay,
} from "../../utils/AccountUtil";

import { CheckoutModel } from "../../models/checkout-model/CheckoutModel";

type SubscriptionModelComponentProps = {
  subModel: SubscriptionModel;
};

export const SubscriptionModelComponent: React.FunctionComponent<
  SubscriptionModelComponentProps
> = ({ subModel }) => {
  const [showCheckout, setShowCheckout] = useState<boolean>(false);
  const onDismissCheckoutModel = () => {
    setShowCheckout(false);
  };
  const onPaymentClick = () => {
    setShowCheckout(true);
  };
  return (
    <>
      <div className="sub-model" onClick={onPaymentClick}>
        <div className="title">{getTimeSpanMembershipDisplay(subModel)}</div>
        <div className="price">
          {getSubscriptionModelPriceDisplay(subModel)}
        </div>
        {subModel.isRecurring && <div className="subscribe">Subscribe</div>}
      </div>

      {showCheckout && (
        <CheckoutModel
          subModel={subModel}
          onDismissCheckoutModel={onDismissCheckoutModel}
        />
      )}
    </>
  );
};
