import "./NavigationHeader.css";

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SignInModel } from "../../models/signin-model/SignInModel";
import { useSelector } from "react-redux";
import { ProfileMenu } from "../profile-menu/ProfileMenu";

type NavigationHeaderProps = {};
const publicUrl = process.env.PUBLIC_URL;

export const NavigationHeader: React.FunctionComponent<
  NavigationHeaderProps
> = ({}) => {
  const [showSignInModel, setShowSignInModel] = useState<boolean>(false);

  const user = useSelector((state: any) => state.userState.user);

  const onDismissSignInModel = () => {
    setShowSignInModel(false);
  };

  useEffect(() => {
    if (user) {
      setShowSignInModel(false);
    }
  }, [user]);

  return (
    <header>
      <div className="nav-hdr">
        <Link to={`/home`}>
          <img
            className="logo"
            src={`${publicUrl}/sweamers-logo.png`}
            alt="sweamers logo"
          />
        </Link>
        <div className="mid-nav-hdr">
          <Link to={`/courses`}>
            <div>Courses</div>
          </Link>
          {/* <Link to={`/contactus`}>
            <div>Contact us</div>
          </Link> */}
        </div>
        <div className="rgt-nav-hdr">
          {user ? (
            <ProfileMenu userDisplayID={user?.email} />
          ) : (
            <div
              className="signin-button"
              onClick={() => setShowSignInModel(true)}
            >
              Sign In
            </div>
          )}
        </div>
      </div>
      {/* TODO: Move to parallel to home page when redux is implemented */}
      {showSignInModel && (
        <SignInModel onDismissSignInModel={onDismissSignInModel} />
      )}
    </header>
  );
};
